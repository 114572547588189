"use client";
import { Form, Formik } from "formik";
import { TextInput } from "./forms/text-input";
import Button from "./forms/button";
import { useAddSubscriberToMailingListMutation } from "../store/apiSlice";
import { useState } from "react";
import { Message } from "./message";
import useLocationInfo from "../hooks/location-info";
import { LocationResult } from "../models/models";
import toast from "react-hot-toast";

export type MailingListSubscriberValues = {
  name: string;
  email: string;
  id: string;
  locationInfo?: LocationResult;
};

type MailingListSignUpProps = {
  mailingListId: string;
  mailingListName?: string;
};

export default function MailingListSignUp({
  mailingListId,
  mailingListName,
}: MailingListSignUpProps) {
  const [subscribe, subscribeStatus] = useAddSubscriberToMailingListMutation();
  const locationInfo = useLocationInfo();
  const [subscribed, setSubscribed] = useState(false);
  return (
    <div
      className={
        "flex w-full sm:w-fit p-4  rounded-xl bg-gray-100 items-center justify-center"
      }
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ name: "", email: "", id: mailingListId, locationInfo }}
        onSubmit={async (values: MailingListSubscriberValues) => {
          try {
            await subscribe(values).unwrap();
            setSubscribed(true);
          } catch (e: any) {
            toast.error("Had problem subscribing - please try again");
          }
        }}
      >
        {subscribed ? (
          <Message
            message={"Thank you for subscribing!"}
            type={"success"}
            title={"Subscribed successfully"}
          />
        ) : (
          <Form className={"flex flex-col gap-y-4"}>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              {mailingListName && (
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Join {mailingListName}
                </h2>
              )}
            </div>

            <TextInput label={"Name"} name={"name"} />
            <TextInput label={"Email"} type={"email"} name={"email"} />
            <div className={"flex"}>
              <Button type={"submit"}>Sign Up</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
