import useProductsToManage from "@hooks/products-to-manage";
import { EventListFilterTypes, Product } from "@models/models";
import Select, { MultiValue, SingleValue } from "react-select";
import {
  getVariantDisplayTitle,
  getVariantShowtimeOnly,
} from "@components/variant-info";
import * as React from "react";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useLazyGetProductQuery } from "@store/apiSlice";

export type SelectedVariantInfo = { id: string; title: string };

export default function VariantSelector({
  onChange,
  defaultValue,
  isMulti,
  productId,
  showVariantTitle,
}: {
  productId?: string;
  defaultValue?: any;
  isMulti: boolean;
  showVariantTitle?: boolean;
  onChange: (
    selected_variant: SelectedVariantInfo | SelectedVariantInfo[],
  ) => void;
}) {
  const [getProduct] = useLazyGetProductQuery();
  const allProducts = useProductsToManage(EventListFilterTypes.SHOW_ALL, 300);
  const [products, setProducts] = useState<Product[]>();

  useEffect(() => {
    if (productId) {
      getProduct(productId).then((r) => setProducts([r.data as Product]));
    } else {
      setProducts(allProducts);
    }
  }, [allProducts]);

  if (!products) return <div>Loading...</div>;
  return (
    <div>
      <Select
        isMulti={isMulti}
        defaultValue={defaultValue}
        getOptionLabel={(v: SelectedVariantInfo) => v.title}
        getOptionValue={(v: SelectedVariantInfo) => v.id}
        onChange={(
          variants:
            | MultiValue<SelectedVariantInfo>
            | SingleValue<SelectedVariantInfo>,
        ) => {
          let result;
          if (_.isArray(variants)) {
            onChange(variants.map((v) => ({ id: v.id, title: v.title })));
          } else if (variants) {
            const casted = variants as SingleValue<{
              id: string;
              title: string;
            }>;
            if (casted) {
              onChange({ id: casted.id, title: casted.title });
            }
          }
        }}
        options={products.flatMap((p) =>
          p.variants.map((v) => ({
            id: v.id,
            title: `${p.title} - ${showVariantTitle ? getVariantDisplayTitle(v, false) : getVariantShowtimeOnly(v)}`,
          })),
        )}
      />
    </div>
  );
}
